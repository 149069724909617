<template>
    <div class="home">
        <!-- 头部 -->
        <myHead></myHead>

        <!-- 添加地址对话框 -->
        <el-dialog title="添加地址" :visible.sync="dialogVisible" width="50%" :close-on-click-modal="false" :show-close="false">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="top" label-width="100px"
                class="demo-ruleForm">
                <el-form-item label="收货人姓名：" prop="name"
                    style="width: calc(50% - 10px); display: inline-block;margin-right: 10px;">
                    <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="手机号码：" prop="phone" style="width: calc(50% - 10px); display: inline-block;clear: both">
                    <el-input v-model="ruleForm.phone"></el-input>
                </el-form-item>
                <el-form-item label="送货地址：" prop="receivingInformation"
                    style="width: calc(50% - 10px); display: inline-block;margin-right: 10px;">
                    <el-cascader :options="options" :props="{ value: 'label', label: 'label' }"
                        v-model="ruleForm.receivingInformation" style="width: 100%;"></el-cascader>
                </el-form-item>
                <el-form-item label="详细地址：" prop="detailedAddress"
                    style="width: calc(50% - 10px); display: inline-block;clear: both">
                    <el-input v-model="ruleForm.detailedAddress"></el-input>
                </el-form-item>
                <div class="mrdz" @click="isSelectedClickAdd">
                    <div class="img">
                        <img v-if="isSelectedAdd" src="@/assets/icon/xuanz.png" alt="">
                        <img v-else src="@/assets/icon/wxz.png" alt="">
                    </div>
                    <div class="text">默认地址</div>
                </div>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="resetForm('ruleForm')">取 消</el-button>
                <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
            </span>
        </el-dialog>


        <!-- 普通 -->
        <div class="payment_content" v-if="paymentTypes == 'PT'">
            <div class="consigneeInfo">
                <div class="tit">
                    <div class="title" @click="getFreightAllClick">收货人信息</div>
                    <div class="add" @click="addArr">新增收货地址</div>
                </div>
                <div class="info cur" v-for="(i, index) in addressList" :key="index" @click="xuanAddreClick(index)">
                    <div class="img">
                        <img v-if="addressMore == index" src="@/assets/icon/xuanz.png" alt="">
                        <img v-else src="@/assets/icon/wxz.png" alt="">
                    </div>
                    <div class="name">{{ i.name }}</div>
                    <div class="address">{{ i.province }}{{ i.city }}{{ i.county }}{{ i.address }}</div>
                    <div class="phone">{{ i.telephone }}</div>
                    <div class="dz" v-if="i.defaults == 1">默认地址</div>
                </div>
            </div>
            <div class="payment">
                <div class="title">支付方式</div>
                <div style="display: flex;position: relative;">
                    <div class="zf" @click="paymentType(index)" v-for="(i, index) in paymentArr" :key="index"
                        :class="[i.act ? 'actShow' : '']">
                        <div class="img">
                            <img :src="i.src" alt="">
                        </div>
                        <span>{{ i.name }}</span>
                    </div>
                    <transition name="fade" mode="out-in">
                        <div v-if="outInShow" class="xxhk" style="position: absolute;top: -25px;left: 415px;">
                            <div class="title">收款账号信息</div>
                            <div class="son">
                                <div class="l">开户名称</div>
                                <div class="z">{{ accountInfo.accountName }}</div>
                                <div class="r cur" @click="copyText(accountInfo.accountName)">复制</div>
                            </div>
                            <div class="son">
                                <div class="l">开户银行</div>
                                <div class="z">{{ accountInfo.bankName }}</div>
                            </div>
                            <div class="son">
                                <div class="l">汇款账号</div>
                                <div class="z">{{ accountInfo.accountNumber }}</div>
                                <div class="r cur" @click="copyText(accountInfo.accountNumber)">复制</div>
                            </div>
                        </div>
                    </transition>
                </div>

            </div>
            <div class="inDistribution">
                <div class="distributionInfo">
                    <div class="l">
                        <div class="titl">配送方式</div>
                        <div class="bt">商家安排</div>
                        <div class="by">{{ freightPrice ? '不包邮' : '包邮' }}</div>
                        <div class="yf">
                            <div class="y">运费</div>
                            <div class="jg">¥{{ freightPrice }}</div>
                        </div>
                    </div>
                    <div class="r">
                        <div class="top">
                            <div class="img">
                                <img :src="sourcingDataPT.mainImage" alt="">
                            </div>
                            <div class="parameter">
                                <div class="merchantsName">商家：{{ sourcingDataPT.mallStore?.storeName }}</div>
                                <div class="cncInfo">
                                    <div class="cncName">{{ sourcingDataPT.title }}</div>
                                    <div class="num">数量：*{{ sourcingDataPT.numXZ }}</div>
                                    <div class="dj" style="display: flex;align-items: center;">单价： <span>¥{{
                                        sourcingDataPT.spuType == 'discuss' ? '面议' :
                                        sourcingDataPT.unitPriceXZ }}</span>
                                        <div v-if="sourcingDataPT.type == '2'"
                                            style="width: 40px;height: 20px;border-radius: 4px;text-align: center;line-height: 20px;font-size: 12px;color: #fff;background-color: #FF4242;margin-left: 5px;">
                                            定金</div>
                                    </div>

                                    <div class="yh">有货</div>
                                </div>
                                <div class="model">{{ sourcingDataPT.valueArrXZ }}</div>
                            </div>
                        </div>
                        <div class="note">
                            <el-input type="textarea" resize="none" :rows="3" maxlength="200" show-word-limit
                                placeholder="备注" v-model="textarea">
                            </el-input>
                        </div>
                    </div>
                </div>
                <div class="coupons" v-if="paymentTypes == 'PT' && sourcingDataPT.spuType != 'discuss'">
                    <div class="tit">
                        <div class="text">优惠券({{ ListOfCoupons.length }}张可用优惠券）</div>
                        <div class="doNotUse cur" @click="WhetherToUseYHQClick">
                            <div class="img">
                                <img v-if="WhetherToUseYHQ" src="@/assets/icon/wxz.png" alt="">
                                <img v-else src="@/assets/icon/xuanz.png" alt="">
                            </div>
                            <div class="tet">不使用优惠券</div>
                        </div>
                    </div>
                    <!-- 无可用优惠券 -->
                    <div v-if="ListOfCoupons.length == 0"
                        style="width: 100%;text-align: center;color: #666;display: flex;justify-content: center;flex-wrap: wrap;">
                        <img src="@/assets/yhq.png" style="width: 100px;object-fit: contain;" alt="">
                        <div style="width: 100%;margin-top: 10px;">暂无可用优惠券</div>
                    </div>
                    <div class="swiper" v-else>
                        <swiper :options="swiperOption3"
                            style="height: 100%;cursor: pointer;border-radius: 4px;overflow: hidden;">
                            <swiper-slide v-for="(i, index) in groupedArray" :key="index"
                                style="height: 150px;display: flex;flex-direction:column;justify-content: space-between;">
                                <div class="yhq">
                                    <div class="son" v-for="(item, index) in i" :key="index" @click="yhqXZClick(item)">
                                        <div class="l">
                                            <div class="jiag" v-if="item.type == '1' || item.type == '4'"> ￥<span>{{
                                                item.reduceAmount ||
                                                item.fixationAmount }}</span> </div>
                                            <div class="jiag" v-if="item.type == '3'"> ￥<span>{{ item.fixationAmount
                                            }}</span> </div>
                                            <div class="jiag" v-if="item.type == '2'"> <span>{{ item.discount }}</span> 折
                                            </div>
                                            <div class="text">满{{ item.premiseAmount }}可用</div>
                                        </div>
                                        <div class="r">
                                            <div class="name">{{ item.storeName }}</div>
                                            <div class="text" v-show="item.suitType == '1'">全部商品可用</div>
                                            <div class="text" v-show="item.suitType == '2'">指定商品可用</div>
                                            <div class="time">
                                                <!-- <div class="djs">仅剩时间：{{ formatNumber(hours) }}:{{ formatNumber(minutes)
                                                }}:{{ formatNumber(seconds) }}</div> -->
                                                <div class="djs">有效期至：{{ item.createTime }}</div>
                                                <div class="img">
                                                    <img v-if="item.isAct" src="@/assets/icon/xuanz.png" alt="">
                                                    <img v-else src="@/assets/icon/wxz.png" alt="">
                                                </div>
                                            </div>
                                        </div>
                                        <img src="@/assets/mjq.png" v-if="item.type == 1" alt="">
                                        <img src="@/assets/jeq.png" v-if="item.type == 3" alt="">
                                        <img src="@/assets/kmq.png" v-if="item.type == 4" alt="">
                                        <img src="@/assets/zkq.png" v-if="item.type == 2" alt="">
                                    </div>


                                    <!-- <div class="son" v-for="(item, index) in ListOfCoupons" :key="index">
                                        <div class="l">
                                            <div class="jiag" v-if="item.type == '1' || item.type == '4'"> ￥<span>{{
                                                item.reduceAmount ||
                                                item.fixationAmount }}</span> </div>
                                            <div class="jiag" v-if="item.type == '3'"> ￥<span>{{ item.fixationAmount
                                            }}</span> </div>
                                            <div class="jiag" v-if="item.type == '2'"> <span>{{ item.discount }}</span> 折
                                            </div>
                                            <div class="text">满{{ item.premiseAmount }}可用</div>
                                        </div>
                                        <div class="r">
                                            <div class="name">{{ item.storeName }}</div>
                                            <div class="text" v-show="item.suitType == '1'">全部商品可用</div>
                                            <div class="text" v-show="item.suitType == '2'">指定商品可用</div>
                                            <div class="btn" v-show="!item.couponUser && !item.couponTname"
                                                @click="claimCouponsClick(item)">立即领券</div>
                                            <div class="btn btn3" v-show="item.couponUser || item.couponTname"
                                                @click="searchClick(item)">立即使用</div>
                                            <div class="prompt">领取后{{ item.validDays }}天内有效</div>
                                        </div>
                                        <img src="@/assets/mjq.png" v-if="item.type == 1" alt="">
                                        <img src="@/assets/jeq.png" v-if="item.type == 3" alt="">
                                        <img src="@/assets/kmq.png" v-if="item.type == 4" alt="">
                                        <img src="@/assets/zkq.png" v-if="item.type == 2" alt="">
                                    </div> -->


                                </div>
                            </swiper-slide>

                            <div class="swiper-pagination" style="opacity: 0;" slot="pagination"></div>
                            <div class="swiper-button-prev" style="width: 20px;" slot="button-prev"></div>
                            <div class="swiper-button-next" style="width: 20px;" slot="button-next"></div>
                        </swiper>
                    </div>
                </div>
            </div>

            <div class="aTotalOf">
                <div class="payment">总商品金额</div>
                <div class="mount">¥{{ sourcingDataPT.unitPriceXZ * sourcingDataPT.numXZ }}</div>
            </div>
            <div class="aTotalOf">
                <div class="payment">运费</div>
                <div class="mount">¥{{ freightPrice }}</div>
            </div>
            <!-- <div class="aTotalOf" v-if="paymentTypes == 'PT'">
                <div class="payment">优惠</div>
                <div class="mount">-¥1000</div>
            </div> -->
            <div class="aTotalOf" v-if="paymentTypes == 'PT'">
                <div class="payment">优惠券抵扣</div>
                <div class="mount">-¥{{ yhqItemJE }}</div>
            </div>

            <div class="toCopeWith">
                <div class="totalAmountOf">
                    <div class="payment">应付总额:</div>
                    <div class="mount">¥{{ sourcingDataPT.unitPriceXZ * sourcingDataPT.numXZ + freightPrice - yhqItemJE }}
                    </div>
                </div>
                <div class="totalAmountOf" v-if="addressList.length > 0">
                    <span>配送至：</span>
                    <span>{{ addressList[addressMore].province }}&nbsp;{{ addressList[addressMore].city }}&nbsp;{{
                        addressList[addressMore].county }}&nbsp;{{ addressList[addressMore].address }}</span>
                    <span>收货人：</span>
                    <span>{{ addressList[addressMore].name }} </span>
                    <span>{{ addressList[addressMore].telephone }}</span>
                </div>
            </div>

            <div class="btn">
                <div class="tij" @click="xiadanClickPT">提交订单</div>
            </div>

        </div>

        <!-- 购物车 -->
        <div class="payment_content" v-else-if="paymentTypes == 'GWC'">
            <div class="consigneeInfo">
                <div class="tit">
                    <div class="title" @click="getFreightAllClick">收货人信息</div>
                    <div class="add" @click="dialogVisible = true">新增收货地址</div>
                </div>
                <div class="info cur" v-for="(i, index) in addressList" :key="index" @click="xuanAddreClick(index)">
                    <div class="img">
                        <img v-if="addressMore == index" src="@/assets/icon/xuanz.png" alt="">
                        <img v-else src="@/assets/icon/wxz.png" alt="">
                    </div>
                    <div class="name">{{ i.name }}</div>
                    <div class="address">{{ i.province }}{{ i.city }}{{ i.county }}{{ i.address }}</div>
                    <div class="phone">{{ i.telephone }}</div>
                    <div class="dz" v-if="i.defaults == 1">默认地址</div>
                </div>
            </div>
            <div class="payment">
                <div class="title">支付方式</div>
                <div style="display: flex;position: relative;">
                    <div class="zf" @click="paymentType(index)" v-for="(i, index) in paymentArr" :key="index"
                        :class="[i.act ? 'actShow' : '']">
                        <div class="img">
                            <img :src="i.src" alt="">
                        </div>
                        <span>{{ i.name }}</span>
                    </div>
                    <transition name="fade" mode="out-in">
                        <div v-if="outInShow" class="xxhk" style="position: absolute;top: -25px;left: 415px;">
                            <div class="title">收款账号信息</div>
                            <div class="son">
                                <div class="l">开户名称</div>
                                <div class="z">{{ accountInfo.accountName }}</div>
                                <div class="r cur" @click="copyText(accountInfo.accountName)">复制</div>
                            </div>
                            <div class="son">
                                <div class="l">开户银行</div>
                                <div class="z">{{ accountInfo.bankName }}</div>
                            </div>
                            <div class="son">
                                <div class="l">汇款账号</div>
                                <div class="z">{{ accountInfo.accountNumber }}</div>
                                <div class="r cur" @click="copyText(accountInfo.accountNumber)">复制</div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>


            <div class="inDistribution" v-for="(i, index) in sourcingDataGWC" :key="index">
                <div class="distributionInfo" style="height: auto;">
                    <div class="l" style="height: auto;">
                        <div class="titl">配送方式</div>
                        <div class="bt">商家安排</div>
                        <div class="by">{{ i.freightPrice ? '不包邮' : '包邮' }}</div>
                        <div class="yf">
                            <div class="y">运费</div>
                            <div class="jg">¥{{ i.freightPrice }}</div>
                        </div>
                    </div>
                    <div class="r" style="height: auto;">
                        <div class="top" style="margin-bottom: 20px;" v-for="(it, inde) in i.mallOrderItemVos" :key="inde">
                            <div class="img">
                                <img style="object-fit: contain;" :src="it.image" alt="">
                            </div>
                            <div class="parameter">
                                <div class="merchantsName">商家：{{ i.storeName }}</div>
                                <div class="cncInfo">
                                    <div class="cncName">{{ it.productName }}</div>
                                    <div class="num">数量：*{{ it.num }}</div>
                                    <div class="dj">单价： <span>¥{{ it.price }}</span></div>
                                    <div class="yh">有货</div>
                                </div>
                                <div class="model">{{ it.skuValues.map(obj => obj.detailName).join(',') }} </div>
                            </div>
                        </div>
                        <div class="note">
                            <el-input type="textarea" resize="none" :rows="3" maxlength="200" show-word-limit
                                placeholder="备注" v-model="i.remark">
                            </el-input>
                        </div>
                    </div>
                </div>
                <div class="coupons" v-if="paymentTypes == 'GWC'">
                    <div class="tit">
                        <div class="text">优惠券({{ i.groupedArrayLength }}张可用优惠券）</div>
                        <div class="doNotUse cur" @click="WhetherToUseYHQClickGWC(index)">
                            <div class="img">
                                <img v-if="i.isUseCoupons" src="@/assets/icon/wxz.png" alt="">
                                <img v-else src="@/assets/icon/xuanz.png" alt="">
                            </div>
                            <div class="tet">不使用优惠券</div>
                        </div>
                    </div>
                    <!-- 无可用优惠券 -->
                    <div v-if="i.groupedArrayLength == 0"
                        style="width: 100%;text-align: center;color: #666;display: flex;justify-content: center;flex-wrap: wrap;">
                        <img src="@/assets/yhq.png" style="width: 100px;object-fit: contain;" alt="">
                        <div style="width: 100%;margin-top: 10px;">暂无可用优惠券</div>
                    </div>
                    <div class="swiper" v-else>
                        <swiper :options="swiperOption3"
                            style="height: 100%;cursor: pointer;border-radius: 4px;overflow: hidden;">
                            <swiper-slide v-for="(it, index2) in i.groupedArray" :key="index2"
                                style="height: 150px;display: flex;flex-direction:column;justify-content: space-between;">
                                <div class="yhq">
                                    <div class="son" v-for="(item, index3) in it" :key="index3"
                                        @click="yhqXZClickGWC(item, index)">
                                        <div class="l">
                                            <div class="jiag" v-if="item.type == '1' || item.type == '4'"> ￥<span>{{
                                                item.reduceAmount ||
                                                item.fixationAmount }}</span> </div>
                                            <div class="jiag" v-if="item.type == '3'"> ￥<span>{{ item.fixationAmount
                                            }}</span> </div>
                                            <div class="jiag" v-if="item.type == '2'"> <span>{{ item.discount }}</span> 折
                                            </div>
                                            <div class="text">满{{ item.premiseAmount }}可用</div>
                                        </div>
                                        <div class="r">
                                            <div class="name">{{ item.storeName }}</div>
                                            <div class="text" v-show="item.suitType == '1'">全部商品可用</div>
                                            <div class="text" v-show="item.suitType == '2'">指定商品可用</div>
                                            <div class="time">
                                                <div class="djs">有效期至：{{ item.validEndTime }}</div>
                                                <div class="img">
                                                    <img v-if="item.isAct" src="@/assets/icon/xuanz.png" alt="">
                                                    <img v-else src="@/assets/icon/wxz.png" alt="">
                                                </div>
                                            </div>
                                        </div>
                                        <img src="@/assets/mjq.png" v-if="item.type == 1" alt="">
                                        <img src="@/assets/jeq.png" v-if="item.type == 3" alt="">
                                        <img src="@/assets/kmq.png" v-if="item.type == 4" alt="">
                                        <img src="@/assets/zkq.png" v-if="item.type == 2" alt="">
                                    </div>

                                </div>
                            </swiper-slide>

                            <div class="swiper-pagination" style="opacity: 0;" slot="pagination"></div>
                            <div class="swiper-button-prev" style="width: 20px;" slot="button-prev"></div>
                            <div class="swiper-button-next" style="width: 20px;" slot="button-next"></div>
                        </swiper>
                    </div>
                </div>
            </div>



            <div class="aTotalOf">
                <div class="payment">总商品金额</div>
                <div class="mount">¥{{ sourcingDataGWC.reduce((total, obj) => total +
                    obj.mallOrderItemVos.reduce((itemTotal, item) => itemTotal + (item.price * item.num), 0), 0) }}</div>
            </div>
            <div class="aTotalOf">
                <div class="payment">运费</div>
                <div class="mount">¥{{ sourcingDataGWC.reduce((total, obj) => total + obj.freightPrice, 0) }}</div>
            </div>
            <div class="aTotalOf">
                <div class="payment">优惠券抵扣</div>
                <div class="mount">-¥{{ sourcingDataGWC.reduce((total, obj) => total + obj.yhqItemJE || 0, 0) || 0 }}</div>
            </div>

            <div class="toCopeWith">
                <div class="totalAmountOf">
                    <div class="payment">应付总额:</div>
                    <div class="mount">¥{{
                        (sourcingDataGWC.reduce((total, obj) => total + obj.mallOrderItemVos.reduce((itemTotal, item) =>
                            itemTotal + (item.price * item.num), 0), 0) +
                            sourcingDataGWC.reduce((total, obj) => total + obj.freightPrice, 0) - sourcingDataGWC.reduce((total,
                                obj) => total + obj.yhqItemJE || 0, 0) || 0).toFixed(2) }}
                    </div>
                </div>
                <div class="totalAmountOf" v-if="addressList.length > 0">
                    <span>配送至：</span>
                    <span>{{ addressList[addressMore].province }}&nbsp;{{ addressList[addressMore].city }}&nbsp;{{
                        addressList[addressMore].county }}&nbsp;{{ addressList[addressMore].address }}</span>
                    <span>收货人：</span>
                    <span>{{ addressList[addressMore].name }} </span>
                    <span>{{ addressList[addressMore].telephone }}</span>
                </div>
            </div>

            <div class="btn">
                <div class="tij" @click="xiadanClickGWC">提交订单</div>
            </div>

        </div>


        <!-- 秒杀 -->
        <div class="payment_content" v-else-if="paymentTypes == 'MS'">
            <div class="consigneeInfo">
                <div class="tit">
                    <div class="title" @click="getFreightAllClick">收货人信息</div>
                    <div class="add" @click="dialogVisible = true">新增收货地址</div>
                </div>
                <div class="info cur" v-for="(i, index) in addressList" :key="index" @click="xuanAddreClick(index)">
                    <div class="img">
                        <img v-if="addressMore == index" src="@/assets/icon/xuanz.png" alt="">
                        <img v-else src="@/assets/icon/wxz.png" alt="">
                    </div>
                    <div class="name">{{ i.name }}</div>
                    <div class="address">{{ i.province }}{{ i.city }}{{ i.county }}{{ i.address }}</div>
                    <div class="phone">{{ i.telephone }}</div>
                    <div class="dz" v-if="i.defaults == 1">默认地址</div>
                </div>
            </div>
            <div class="payment">
                <div class="title">支付方式</div>
                <div style="display: flex;position: relative;">
                    <div class="zf" @click="paymentType(index)" v-for="(i, index) in paymentArr" :key="index"
                        :class="[i.act ? 'actShow' : '']">
                        <div class="img">
                            <img :src="i.src" alt="">
                        </div>
                        <span>{{ i.name }}</span>
                    </div>
                    <transition name="fade" mode="out-in">
                        <div v-if="outInShow" class="xxhk" style="position: absolute;top: -25px;left: 415px;">
                            <div class="title">收款账号信息</div>
                            <div class="son">
                                <div class="l">开户名称</div>
                                <div class="z">{{ accountInfo.accountName }}</div>
                                <div class="r cur" @click="copyText(accountInfo.accountName)">复制</div>
                            </div>
                            <div class="son">
                                <div class="l">开户银行</div>
                                <div class="z">{{ accountInfo.bankName }}</div>
                            </div>
                            <div class="son">
                                <div class="l">汇款账号</div>
                                <div class="z">{{ accountInfo.accountNumber }}</div>
                                <div class="r cur" @click="copyText(accountInfo.accountNumber)">复制</div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
            <div class="inDistribution">
                <div class="distributionInfo">
                    <div class="l">
                        <div class="titl">配送方式</div>
                        <div class="bt">商家安排</div>
                        <div class="by">{{ freightPrice ? '不包邮' : '包邮' }}</div>
                        <div class="yf">
                            <div class="y">运费</div>
                            <div class="jg">¥{{ freightPrice }}</div>
                        </div>
                    </div>
                    <div class="r">
                        <div class="top">
                            <div class="img">
                                <img :src="sourcingDataMS.mallSku?.img" alt="">
                            </div>
                            <div class="parameter">
                                <div class="merchantsName">商家：{{ sourcingDataMS.mallStore.storeName }}</div>
                                <div class="cncInfo">
                                    <div class="cncName">{{ sourcingDataMS.mallSpu?.title }}</div>
                                    <div class="num">数量：* 1</div>
                                    <div class="dj">单价： <span>¥{{ sourcingDataMS.seckillPrice }}</span></div>
                                    <div class="yh">有货</div>
                                </div>
                                <div class="model">{{ sourcingDataMS.mallSku.mallSkuValues.map(item =>
                                    item.detailName).join('/') }}</div>
                            </div>
                        </div>
                        <div class="note">
                            <el-input type="textarea" resize="none" :rows="3" maxlength="200" show-word-limit
                                placeholder="备注" v-model="textarea">
                            </el-input>
                        </div>
                    </div>
                </div>
            </div>

            <div class="aTotalOf">
                <div class="payment">总商品金额</div>
                <div class="mount">¥{{ sourcingDataMS.seckillPrice }}</div>
            </div>
            <div class="aTotalOf">
                <div class="payment">运费</div>
                <div class="mount">¥{{ freightPrice }}</div>
            </div>


            <div class="toCopeWith">
                <div class="totalAmountOf">
                    <div class="payment">应付总额:</div>
                    <div class="mount">¥{{ sourcingDataMS.seckillPrice + freightPrice }}</div>
                </div>
                <div class="totalAmountOf" v-if="addressList.length > 0">
                    <span>配送至：</span>
                    <span>{{ addressList[addressMore].province }}&nbsp;{{ addressList[addressMore].city }}&nbsp;{{
                        addressList[addressMore].county }}&nbsp;{{ addressList[addressMore].address }}</span>
                    <span>收货人：</span>
                    <span>{{ addressList[addressMore].name }} </span>
                    <span>{{ addressList[addressMore].telephone }}</span>
                </div>
            </div>

            <div class="btn">
                <div class="tij" @click="xiadanFlashKillingClick">提交订单</div>
            </div>

        </div>

        <!-- 寻源 -->
        <div class="payment_content" v-else>
            <div class="payment">
                <div class="title">支付方式</div>
                <div style="display: flex;position: relative;">
                    <div class="zf" @click="paymentType(index)" v-for="(i, index) in paymentArr" :key="index"
                        :class="[i.act ? 'actShow' : '']">
                        <div class="img">
                            <img :src="i.src" alt="">
                        </div>
                        <span>{{ i.name }}</span>
                    </div>
                    <transition name="fade" mode="out-in">
                        <div v-if="outInShow" class="xxhk" style="position: absolute;top: -25px;left: 415px;">
                            <div class="title">收款账号信息</div>
                            <div class="son">
                                <div class="l">开户名称</div>
                                <div class="z">{{ accountInfo.accountName }}</div>
                                <div class="r cur" @click="copyText(accountInfo.accountName)">复制</div>
                            </div>
                            <div class="son">
                                <div class="l">开户银行</div>
                                <div class="z">{{ accountInfo.bankName }}</div>
                            </div>
                            <div class="son">
                                <div class="l">汇款账号</div>
                                <div class="z">{{ accountInfo.accountNumber }}</div>
                                <div class="r cur" @click="copyText(accountInfo.accountNumber)">复制</div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
            <div class="inDistribution">
                <div class="distributionInfo">
                    <div class="l">
                        <div class="titl">配送方式</div>
                        <div class="bt">商家安排</div>
                        <div class="by">{{ sourcingData.offerType }}</div>
                        <div class="yf">
                            <div class="y">运费</div>
                            <div class="jg">¥{{ sourcingData.freightAmount }}</div>
                        </div>
                    </div>
                    <div class="r">
                        <div class="top">
                            <div class="img">
                                <img :src="sourcingData.mallSku?.img" alt="">
                            </div>
                            <div class="parameter">
                                <div class="merchantsName">商家：{{ sourcingData.mallStore?.storeName }}</div>
                                <div class="cncInfo">
                                    <div class="cncName">{{ sourcingData.mallSpu?.title }}</div>
                                    <div class="num">数量：*{{ sourcingData.offerNum }}</div>
                                    <div class="dj">单价： <span>¥{{ sourcingData.unitPrice }}</span></div>
                                    <div class="yh">有货</div>
                                </div>
                                <div class="model">{{ sourcingData.mallSku.mallSkuValues.map(item =>
                                    item.detailName).join('/') }}</div>
                            </div>
                        </div>
                        <div class="note">
                            <el-input type="textarea" resize="none" :rows="3" maxlength="200" show-word-limit
                                placeholder="备注" v-model="textarea">
                            </el-input>
                        </div>
                    </div>
                </div>
            </div>

            <div class="aTotalOf">
                <div class="payment">总商品金额</div>
                <div class="mount">¥{{ sourcingData.totalPrice }}</div>
            </div>
            <div class="aTotalOf">
                <div class="payment">运费</div>
                <div class="mount">¥{{ sourcingData.freightAmount }}</div>
            </div>

            <div class="toCopeWith">
                <div class="totalAmountOf">
                    <div class="payment">应付总额:</div>
                    <div class="mount">¥{{ sourcingData.totalPrice + sourcingData.freightAmount }}</div>
                </div>
                <div class="totalAmountOf" v-if="addressList.length > 0">
                    <span>配送至：</span>
                    <!-- <span>{{ addressList[addressMore].province }}&nbsp;{{ addressList[addressMore].city }}&nbsp;{{
                        addressList[addressMore].county }}&nbsp;{{ addressList[addressMore].address }}</span> -->
                    <span>{{ sourcingData.address }} </span>
                    <span>收货人：</span>
                    <span>{{ addressList[addressMore].name }} </span>
                    <span>{{ addressList[addressMore].telephone }}</span>
                </div>
            </div>

            <div class="btn">
                <div class="tij" @click="xiadanClick">提交订单</div>
            </div>

        </div>



        <!-- 汇款凭证对话框 -->
        <!-- <el-dialog title="线下汇款后提交凭证" :visible.sync="HKshow" width="50%" :before-close="handleCloseHK" center>
            <div class="pzDialo">
                <div class="l">
                    <div class="title">收款账号信息</div>
                    <div class="con">
                        <div class="ti">开户名称</div>
                        <div class="box">
                            <div class="text">{{ accountInfo.accountName }}</div>
                            <div class="fz cur" @click="copyText(accountInfo.accountName)">复制</div>
                        </div>
                        <div class="ti">开户银行</div>
                        <div class="box">
                            <div class="text">{{ accountInfo.bankName }}</div>
                        </div>
                        <div class="ti">汇款账号</div>
                        <div class="box">
                            <div class="text">{{ accountInfo.accountNumber }}</div>
                            <div class="fz cur" @click="copyText(accountInfo.accountNumber)">复制</div>
                        </div>
                    </div>
                </div>
                <div class="r">
                    <div class="title">提交汇款凭证</div>
                    <div class="con">
                        <div class="ti" style="color: #333;margin-bottom: 5px;">汇款单号<span
                                style="color: #FF2121;margin-left: 3px;">*</span>
                        </div>
                        <div class="box" style="margin-bottom: 10px;">
                            <input type="text" v-model="userPaymentNumber" placeholder="请输入汇款单号" class="input">
                        </div>
                        <div class="ti" style="color: #333;margin-bottom: 5px;">备注</div>
                        <div class="box" style="margin-bottom: 10px;">
                            <textarea placeholder="备注信息" v-model="userPaymentNote"> </textarea>
                        </div>
                        <div class="ti" style="color: #333;display: flex;height: 68px;overflow: hidden;">上传凭证<span
                                style="color: #FF2121;margin-left: 3px;">*</span>

                            <div style="margin-left: 10px;width: 258px;">
                                <el-upload action="https://api.cnc-x.cn/mall/uploads" :data="{ type: '2' }" :limit="3"
                                    list-type="picture-card" :on-preview="handlePictureCardPreview"
                                    :on-remove="handleRemove" :on-change="handleChange">
                                    <i class="el-icon-plus"></i>
                                </el-upload>
                                <el-dialog :modal="false" :visible.sync="dialogVisibleUrl">
                                    <img width="100%" :src="dialogImageUrl" alt="">
                                </el-dialog>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="HKshowClick">确 定</el-button>
            </span>
        </el-dialog> -->
        <offlineHK :accountInfo="accountInfo" :HKshow="HKshow" :oid="oid" @HKshowCl="HKshowCl" />




        <!-- 底部 -->
        <bottomContent></bottomContent>
    </div>
</template>

<script>
import Clipboard from 'clipboard';
import offlineHK from "@/pages/myPage/offlineHK.vue";
import myHead from "@/components/myHead.vue";
import bottomContent from "@/components/bottomContent.vue";
import { getAddress, postAddress, postSourcingOrder, getmyCoupon, postSourcingPayment, getFreightAll, getFreightOrder, getStoreAccount } from "@/utils/api/myApi/index"

export default {
    name: 'payment',
    components: {
        myHead,
        bottomContent,
        offlineHK
    },
    data() {
        return {
            oid: '',
            typeOrder: false,
            paymentArr: [
                {
                    name: '微信支付',
                    src: require('@/assets/icon/wxzf.png'),
                    act: true
                },
                {
                    name: '支付宝支付',
                    src: require('@/assets/icon/zfbzf.png'),
                    act: false
                },
                {
                    name: '线下汇款',
                    src: require('@/assets/icon/xxhk.png'),
                    act: false
                },
            ],
            textarea: '',
            swiperOption3: {
                slidesPerView: 1,
                // 设置分页器
                pagination: {
                    el: ".swiper-pagination",
                    // 设置点击可切换
                    clickable: true,
                },
                // 设置前进后退按钮
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                // 设置轮播可循环
                loop: false,
            },
            // 倒计时
            countDownDate: new Date("2023-05-07 15:18:40").getTime(), // 目标日期
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
            timer: null, // 定时器
            // 地址
            addressList: [],
            // 默认选中第一个地址
            addressMore: 0,
            // 添加地址数据
            ruleForm: {
                name: '',
                phone: '',
                receivingInformation: '',
                detailedAddress: ''
            },
            // 添加对话框
            dialogVisible: false,
            // 校验
            rules: {
                name: [
                    { required: true, message: '请输收货人名称', trigger: 'blur' },
                    { min: 1, max: 5, message: '长度在 1 到 5 个字符', trigger: 'blur' }
                ],
                phone: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                ],
                receivingInformation: [
                    { required: true, message: '请选择收货地址', trigger: 'change' },
                ],
                detailedAddress: [
                    { required: true, message: '请输入详细地址', trigger: 'blur' },
                ],
            },
            // 全国地址数据
            options: [],
            // 添加地址时是否默认地址
            isSelectedAdd: false,
            // 商品信息!--
            sourcingData: {},
            // 支付方式
            payType: 'WX',
            // 秒杀运费
            freightPrice: '',
            // 订单类型
            paymentTypes: '',
            // 秒杀数据
            sourcingDataMS: {},
            // 普通数据
            sourcingDataPT: {},
            // 可用优惠券
            ListOfCoupons: [],
            groupedArray: [],
            // 选中的优惠券
            yhqItem: {},
            // 是否使用优惠券
            WhetherToUseYHQ: false,
            // 优惠券优惠掉的金额
            yhqItemJE: 0,
            // 购物车数据
            sourcingDataGWC: [],
            // 购物车店铺长度
            gwcLength: 0,
            // 汇款信息
            outInShow: false,
            accountInfo: {},
            // 线下汇款凭证弹窗
            HKshow: false
        }
    },
    watch: {
        // 监听地址变化 重新计算运费
        addressMore(newVal, oldVal) {
            if (this.paymentTypes == 'MS' || this.paymentTypes == 'PT') {
                this.getFreightAllClick()
            }
            if (this.paymentTypes == 'GWC') {
                this.getFreightAllClickGWC()
            }
        }
    },
    mounted() {
        this.$store.commit("getUserInfoData")
        this.paymentTypes = this.$route.query.type

        if (this.paymentTypes == 'MS') {
            this.sourcingDataMS = JSON.parse(localStorage.getItem('mallStoreObjMS'));
        } else if (this.paymentTypes == 'PT') {
            this.sourcingDataPT = JSON.parse(localStorage.getItem('mallStoreObjPT'));
            this.getAvailableCoupons() //获取可用优惠券
        } else if (this.paymentTypes == 'GWC') {
            this.sourcingDataGWC = JSON.parse(localStorage.getItem('mallStoreObjGWC'));
            this.gwcLength = this.sourcingDataGWC.length
            if (this.gwcLength == 1) {
                this.paymentArr = [
                    {
                        name: '微信支付',
                        src: require('@/assets/icon/wxzf.png'),
                        act: true
                    },
                    {
                        name: '支付宝支付',
                        src: require('@/assets/icon/zfbzf.png'),
                        act: false
                    },
                    {
                        name: '线下汇款',
                        src: require('@/assets/icon/xxhk.png'),
                        act: false
                    },
                ]
            } else {
                this.paymentArr = [
                    {
                        name: '微信支付',
                        src: require('@/assets/icon/wxzf.png'),
                        act: true
                    },
                    {
                        name: '支付宝支付',
                        src: require('@/assets/icon/zfbzf.png'),
                        act: false
                    },
                ]
            }

            this.sourcingDataGWC.map(d => this.$set(d, 'remark', ''))
            this.sourcingDataGWC.map(d => d.mallOrderItemVos.map(b => this.$set(b, 'priceYHQ', b.price * b.num))) // 优惠券后价格
            this.getAvailableCouponsGWC() //获取可用优惠券
        }
        this.getStoreAccountById()

    },
    created() {
        this.sourcingData = JSON.parse(localStorage.getItem('mallStoreObjXY'));
        this.options = this.$addresses
        // 更新倒计时
        this.updateCountdown();
        this.timer = setInterval(this.updateCountdown, 1000); // 每秒更新一次
        // 获取地址
        this.getAddressList()
    },
    methods: {
        // 复制
        copyText(shipNo) {
            const textToCopy = shipNo;
            const clipboard = new Clipboard('.el-button', {
                text: () => textToCopy
            });
            let that = this
            clipboard.on('success', () => {
                that.$message.success('复制成功')
                clipboard.destroy();
            });
            clipboard.on('error', () => {
                that.$message.error('复制失败')
                clipboard.destroy();
            });
            clipboard.onClick(event);
        },
        HKshowCl() {
            this.HKshow = false
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
            this.$router.push('/myIndex/myOrder?actMyNum=0')
        },
        addArr() {
            // if(!this.$store.state.userInfo.userId) return this.$message.error('请先登录')
            this.dialogVisible = true
        },
        // 获取可用优惠券 购物车
        getAvailableCouponsGWC() {
            this.sourcingDataGWC.map(async d => {
                let data = {
                    status: 0,
                    current: 1,
                    size: 100,
                    storeId: d.storeId,
                    spuId: d.mallOrderItemVos.map(obj => obj.spuId).join(',')
                }
                const res = await getmyCoupon(data)
                if (res.statusCode == 8201) {
                    let ListOfCoupons = res.data.records
                    const groupSize = 3;
                    const groupedArray = [];
                    for (let i = 0; i < ListOfCoupons.length; i += groupSize) {
                        const group = ListOfCoupons.slice(i, i + groupSize);
                        groupedArray.push(group);
                    }
                    groupedArray.map(d => d.map(b => {
                        this.$set(b, 'isAct', false)
                    }))
                    this.$set(d, 'groupedArrayLength', res.data.records.length)
                    this.$set(d, 'isUseCoupons', false) // 默认不使用优惠券 
                    this.$set(d, 'yhqItemJE', 0) // 优惠金额 
                    this.$set(d, 'groupedArray', groupedArray)
                }
            })
            console.log(this.sourcingDataGWC, '购物车')
        },
        // 获取可用优惠券
        async getAvailableCoupons() {
            const res = await getmyCoupon({
                status: 0,
                current: 1,
                size: 100,
                storeId: this.sourcingDataPT.storeId,
                spuId: this.sourcingDataPT.spuId
            })
            if (res.statusCode == 8201) {
                this.ListOfCoupons = res.data.records
                const groupSize = 3;
                const groupedArray = [];

                for (let i = 0; i < this.ListOfCoupons.length; i += groupSize) {
                    const group = this.ListOfCoupons.slice(i, i + groupSize);
                    groupedArray.push(group);
                }
                groupedArray.map(d => d.map(b => {
                    this.$set(b, 'isAct', false)
                }))

                this.groupedArray = groupedArray

                console.log(groupedArray, 'groupedArray');
            }
        },
        // 不使用优惠券
        WhetherToUseYHQClick() {
            this.WhetherToUseYHQ = false
            this.groupedArray.map(d => d.map(b => {
                b.isAct = false
            }))
            this.yhqItem = ''
            this.yhqItemJE = 0
        },
        // 不使用优惠券 购物车
        WhetherToUseYHQClickGWC(index) {
            this.sourcingDataGWC[index].isUseCoupons = false
            this.sourcingDataGWC[index].groupedArray.map(d => d.map(b => {
                b.isAct = false
            }))
            this.sourcingDataGWC[index].yhqItemJE = 0
            this.sourcingDataGWC[index].yhqItem = {}
            this.sourcingDataGWC[index].mallOrderItemVos.map(m => m.priceYHQ = m.price * m.num)
            console.log(this.sourcingDataGWC)
        },
        // 选中优惠券 购物车
        yhqXZClickGWC(val, index) {
            this.sourcingDataGWC[index].groupedArray.map(d => d.map(b => {
                b.isAct = false
                if (val.id == b.id) {
                    let zongJia = this.sourcingDataGWC[index].mallOrderItemVos.reduce((total, obj) => total + obj.totalFee, 0) + this.sourcingDataGWC[index].freightPrice
                    if (val.suitType == '2') {  // 指定商品可用 
                        console.log(val)
                        console.log(this.sourcingDataGWC[index].mallOrderItemVos)
                        const match = this.sourcingDataGWC[index].mallOrderItemVos.find(item => val.spuIds.includes(item.spuId));
                        console.log(match, 'match', this.sourcingDataGWC) // 指定优惠券匹配的第一个商品
                        if (val.premiseAmount > zongJia) return this.$message.error('不满足使用条件')
                        this.$set(this.sourcingDataGWC[index], 'yhqItem', b)
                        b.isAct = true
                        this.sourcingDataGWC[index].isUseCoupons = true
                        this.sourcingDataGWC[index].mallOrderItemVos.map(m => m.priceYHQ = m.price * m.num)
                        if (b.type != 2) { // 满减券
                            this.sourcingDataGWC[index].yhqItemJE = +(b.fixationAmount || b.reduceAmount).toFixed(2)
                            match.priceYHQ = match.price * match.num - (+(b.fixationAmount || b.reduceAmount).toFixed(2))
                        } else { // 折扣券
                            this.sourcingDataGWC[index].yhqItemJE = +(match.price - (match.price * (b.discount * 0.1))).toFixed(2)
                            match.priceYHQ = +((match.price * match.num) * (val.discount * 0.1)).toFixed(2)
                        }
                    } else {  // 全部商品可用
                        if (val.premiseAmount > zongJia) return this.$message.error('不满足使用条件')
                        this.$set(this.sourcingDataGWC[index], 'yhqItem', b)
                        b.isAct = true
                        this.sourcingDataGWC[index].isUseCoupons = true
                        this.sourcingDataGWC[index].mallOrderItemVos.map(m => m.priceYHQ = m.price * m.num)
                        if (b.type != 2) { // 满减券
                            this.sourcingDataGWC[index].yhqItemJE = +(b.fixationAmount || b.reduceAmount).toFixed(2)
                            this.sourcingDataGWC[index].mallOrderItemVos.map(b => {
                                // b.priceYHQ = b.price * b.num - (+((val.fixationAmount || val.reduceAmount) / this.sourcingDataGWC[index].mallOrderItemVos.length).toFixed(2))
                                let tatalNum = this.sourcingDataGWC[index].mallOrderItemVos.reduce((sum, product) => sum + product.totalFee, 0)
                                // b.priceYHQ = +(b.totalFee - ((b.totalFee / tatalNum) * (val.fixationAmount || val.reduceAmount))).toFixed(2)
                                b.priceYHQ = +((b.price * b.num) - ((b.totalFee / tatalNum) * (val.fixationAmount || val.reduceAmount))).toFixed(2)
                            })
                        } else { // 折扣券
                            this.sourcingDataGWC[index].yhqItemJE = +(zongJia - (zongJia * (b.discount * 0.1))).toFixed(2)
                            this.sourcingDataGWC[index].mallOrderItemVos.map(b => {
                                b.priceYHQ = +((b.price * b.num) * (val.discount * 0.1)).toFixed(2)
                            })
                        }
                    }

                    return
                    let zongJ = this.sourcingDataGWC[index].mallOrderItemVos.reduce((total, obj) => total + obj.totalFee, 0) + this.sourcingDataGWC[index].freightPrice
                    this.$set(this.sourcingDataGWC[index], 'yhqItem', b)

                    if (val.premiseAmount > zongJ) return this.$message.error('不满足使用条件')
                    b.isAct = true
                    if (b.type != 2) {
                        this.sourcingDataGWC[index].yhqItemJE = +(b.fixationAmount || b.reduceAmount).toFixed(2)
                    } else {
                        this.sourcingDataGWC[index].yhqItemJE = +(zongJ - (zongJ * (b.discount * 0.1))).toFixed(2)
                    }
                }
            }))
            // console.log(this.sourcingDataGWC)
        },
        // 选中优惠券
        yhqXZClick(val) {
            this.groupedArray.map(d => d.map(b => {
                b.isAct = false
                if (val.id == b.id) {
                    let zongJ = this.sourcingDataPT.unitPriceXZ * this.sourcingDataPT.numXZ + this.freightPrice
                    if (val.premiseAmount > zongJ) return this.$message.error('不满足使用条件')
                    b.isAct = true
                    this.yhqItem = b
                    if (b.type != 2) {
                        this.yhqItemJE = +(b.fixationAmount || b.reduceAmount).toFixed(2)
                    } else {
                        this.yhqItemJE = +(zongJ - (zongJ * (b.discount * 0.1))).toFixed(2)
                    }

                    this.WhetherToUseYHQ = true
                }
            }))
            console.log(this.yhqItem)
        },
        // 查询运费 购物车
        getFreightAllClickGWC() {
            this.sourcingDataGWC.map(async d => {
                let freightVo = []
                d.mallOrderItemVos.map(b => {
                    let obj = {
                        skuId: b.skuId,
                        num: b.num,
                        spuId: b.spuId,
                        totalFee: b.totalFee
                    }
                    freightVo.push(obj)
                })
                const res = await getFreightAll({
                    freightVo: JSON.stringify(freightVo),
                    city: this.addressList[this.addressMore].city == '市辖区' ? this.addressList[this.addressMore].province : this.addressList[this.addressMore].city
                })
                if (res.statusCode == 8201) {
                    let freightPrice = res.data.reduce((total, obj) => total + obj.freightPrice, 0);
                    this.$set(d, 'freightPrice', freightPrice)
                }
            })
        },
        // 查询运费
        async getFreightAllClick() {
            let freightVo = []
            if (this.paymentTypes == 'MS') {
                const storedObj = JSON.parse(localStorage.getItem('mallStoreObjMS'));
                freightVo.push({
                    skuId: storedObj.skuId,
                    num: 1,
                    spuId: storedObj.spuId,
                    totalFee: storedObj.seckillPrice
                })
            } else {
                const storedObj = JSON.parse(localStorage.getItem('mallStoreObjPT'));
                freightVo.push({
                    skuId: storedObj.skuIdXZ,
                    num: storedObj.numXZ,
                    spuId: storedObj.spuId,
                    totalFee: storedObj.unitPriceXZ * storedObj.numXZ
                })
            }
            const res = await getFreightAll({
                freightVo: JSON.stringify(freightVo),
                userId: '',
                city: this.addressList[this.addressMore].city
            })
            if (res.statusCode == 8201) {
                this.freightPrice = res.data[0].freightPrice
            }
        },
        // 下单 购物车
        async xiadanClickGWC() {
            console.log(this.payType)
            if (this.addressList.length == 0) {
                return this.$message.error('请先添加收货地址')
            }
            if (!this.addressList[this.addressMore].province) return this.$message.error('请添加收获地址')
            let sourcingDataGWC = this.sourcingDataGWC
            let orderStoreDTOSAll = []
            sourcingDataGWC.map(d => {
                let orderSkuDTOSAll = []
                d.mallOrderItemVos.map((b, index) => {
                    orderSkuDTOSAll.push({
                        couponUserId: d.yhqItem ? d.yhqItem.id : '', // 用户电子券ID couponId
                        freightPrice: d.freightPrice, // 运费金额
                        // paymentCouponPrice: (b.totalFee - (b.priceYHQ * b.num) ), // 电子券支付金额
                        paymentCouponPrice: 0, // 电子券支付金额
                        paymentPoints: 0, // 支付积分
                        paymentPointsPrice: 0, // 积分抵扣金额 sourcingDataPT.unitPriceXZ * sourcingDataPT.numXZ + this.freightPrice - this.yhqItemJE
                        paymentPrice: b.priceYHQ + d.freightPrice, // 支付金额 < 
                        quantity: b.num, // 数量
                        skuId: b.skuId,   // skuId 
                        spuId: b.spuId,   // 商品Id
                        totalPrice: b.totalFee,   // 总价
                        // num: 1, // < 
                        // totalFee: storedObj.seckillPrice  // <
                    })
                })
                orderStoreDTOSAll.push(
                    {
                        freightPrice: d.freightPrice, // 运费金额 
                        orderSkuDTOS: orderSkuDTOSAll,
                        remark: d.remark, // 备注
                        storeId: d.storeId // 所属商家的ID
                    }
                )
            })
            let parmes = {
                address: this.addressList[this.addressMore].address, // 联系地址
                appId: "", // 应用ID
                appType: "", // 来源应用类型1、小程序；2、公众号 3：app
                city: this.addressList[this.addressMore].city, // 城市
                county: this.addressList[this.addressMore].county, // 区/县
                // discountNum: 0, // <
                marketId: '', // 营销ID（代销ID、拼团ID、秒杀ID）
                orderChannel: "app", // 应用类型 （app:APP、mini:小程序）
                orderStoreDTOS: orderStoreDTOSAll,
                otype: "PT",
                payNum: 0,
                payType: this.payType, // 付款方式 （ZFB:支付宝、WX:微信）    
                payeeType: 1, // 收款类型：1:平台代收 2:商家自主收款
                province: this.addressList[this.addressMore].province, // 省
                relationId: '', //  营销记录ID（代销记录ID、拼团记录组ID（团长的拼团记录ID）、秒杀会场ID）
                type: "", // 类型： 1:全款 2:定金
                uname: this.addressList[this.addressMore].name, // 
                userAddressId: "", // 用户收货地址ID
                userId: "", // 用户id
                utelephone: this.addressList[this.addressMore].telephone // 
            }
            // return console.log(parmes)
            const res = await getFreightOrder(parmes)
            if (res.statusCode == 8201) {

                if (this.payType == 'HK' && this.gwcLength == 1) {
                    this.HKshow = true
                    this.oid = res.data.oid[0]
                } else {
                    this.paymentClickGWC(res.data.oid)
                }
            }
        },
        // 下单 普通商品
        async xiadanClickPT() {
            let sourcingDataPT = this.sourcingDataPT
            if (this.addressList.length == 0) {
                return this.$message.error('请先添加收货地址')
            }
            if (!this.addressList[this.addressMore].province) return this.$message.error('请添加收货地址')
            let parmes = {
                address: this.addressList[this.addressMore].address, // 联系地址
                appId: "", // 应用ID
                appType: "", // 来源应用类型1、小程序；2、公众号 3：app
                city: this.addressList[this.addressMore].city, // 城市
                county: this.addressList[this.addressMore].county, // 区/县
                // discountNum: 0, // <
                marketId: '', // 营销ID（代销ID、拼团ID、秒杀ID）
                orderChannel: "app", // 应用类型 （app:APP、mini:小程序）
                orderStoreDTOS: [
                    {
                        freightPrice: this.freightPrice, // 运费金额 
                        orderSkuDTOS: [
                            {
                                couponUserId: this.yhqItem.id, // 用户电子券ID couponId
                                freightPrice: 0, // 运费金额
                                paymentCouponPrice: 0, // 电子券支付金额
                                paymentPoints: 0, // 支付积分
                                paymentPointsPrice: 0, // 积分抵扣金额
                                paymentPrice: sourcingDataPT.unitPriceXZ * sourcingDataPT.numXZ + this.freightPrice - this.yhqItemJE, // 支付金额 < 
                                quantity: sourcingDataPT.numXZ, // 数量
                                skuId: sourcingDataPT.skuIdXZ,   // skuId 
                                spuId: sourcingDataPT.spuId,   // 商品Id
                                totalPrice: sourcingDataPT.unitPriceXZ * sourcingDataPT.numXZ + this.freightPrice,   // 总价
                                // num: 1, // < 
                                // totalFee: storedObj.seckillPrice  // <
                            }
                        ],
                        remark: this.textarea, // 备注
                        storeId: sourcingDataPT.storeId // 所属商家的ID
                    }
                ],
                otype: sourcingDataPT.spuType == 'discuss' ? 'DISCUSS' : 'PT',
                payNum: 0,
                payType: this.payType, // 付款方式 （ZFB:支付宝、WX:微信）
                payeeType: 1, // 收款类型：1:平台代收 2:商家自主收款
                province: this.addressList[this.addressMore].province, // 省
                relationId: '', //  营销记录ID（代销记录ID、拼团记录组ID（团长的拼团记录ID）、秒杀会场ID）
                type: "", // 类型： 1:全款 2:定金
                uname: this.addressList[this.addressMore].name, // 
                userAddressId: "", // 用户收货地址ID
                userId: "", // 用户id
                utelephone: this.addressList[this.addressMore].telephone // 
            }
            const res = await getFreightOrder(parmes)
            if (res.statusCode == 8201) {
                if (sourcingDataPT.spuType == 'discuss') {
                    this.$router.push('/myIndex/myOrder?actMyNum=0')
                } else {
                    if (this.payType == 'HK') {
                        this.HKshow = true
                        this.oid = res.data.oid[0]
                    } else {
                        this.paymentClick(res.data.oid[0])
                    }
                }
            }
        },
        // 下单 秒杀
        async xiadanFlashKillingClick() {
            const storedObj = JSON.parse(localStorage.getItem('mallStoreObjMS'));
            console.log(storedObj, '秒杀商品信息')
            let parmes = {
                address: this.addressList[this.addressMore].address, // 联系地址
                appId: "", // 应用ID
                appType: "", // 来源应用类型1、小程序；2、公众号 3：app
                city: this.addressList[this.addressMore].city, // 城市
                county: this.addressList[this.addressMore].county, // 区/县
                // discountNum: 0, // <
                marketId: storedObj.seckillId, // 营销ID（代销ID、拼团ID、秒杀ID）
                orderChannel: "app", // 应用类型 （app:APP、mini:小程序）
                orderStoreDTOS: [
                    {
                        freightPrice: this.freightPrice, // 运费金额 
                        orderSkuDTOS: [
                            {
                                couponUserId: "", // 用户电子券ID
                                freightPrice: 0, // 运费金额
                                paymentCouponPrice: 0, // 电子券支付金额
                                paymentPoints: 0, // 支付积分
                                paymentPointsPrice: 0, // 积分抵扣金额
                                paymentPrice: this.sourcingDataMS.seckillPrice + this.freightPrice, // 支付金额 < 
                                quantity: 1, // 数量
                                skuId: storedObj.skuId,   // skuId 
                                spuId: storedObj.spuId,   // 商品Id
                                totalPrice: storedObj.seckillPrice,   // 总价
                                // num: 1, // < 
                                // totalFee: storedObj.seckillPrice  // <
                            }
                        ],
                        remark: this.textarea, // 备注
                        storeId: storedObj.storeId // 所属商家的ID
                    }
                ],
                otype: "SECKILL",
                payNum: 0,
                payType: this.payType, // 付款方式 （ZFB:支付宝、WX:微信）
                payeeType: 1, // 收款类型：1:平台代收 2:商家自主收款
                province: this.addressList[this.addressMore].province, // 省
                relationId: storedObj.seckillHall.seckillHallId, //  营销记录ID（代销记录ID、拼团记录组ID（团长的拼团记录ID）、秒杀会场ID）
                type: "", // 类型： 1:全款 2:定金
                uname: this.addressList[this.addressMore].name, // 
                userAddressId: "", // 用户收货地址ID
                userId: "", // 用户id
                utelephone: this.addressList[this.addressMore].telephone // 
            }
            const res = await getFreightOrder(parmes)
            if (res.statusCode == 8201) {
                if (this.payType == 'HK') {
                    this.HKshow = true
                    this.oid = res.data.oid[0]
                } else {
                    this.paymentClick(res.data.oid[0])
                }
            }
        },
        // 下单 寻源
        async xiadanClick() {
            let data = {
                tradeType: 'JSAPI',
                payType: this.payType, // this.payType == 'WX'? 'WX' : this.payType == 'ZFB' ? 'ZFB' : ''
                offerId: this.sourcingData.offerId
            }
            console.log(data)
            const res = await postSourcingOrder(data)
            if (res.statusCode == 8201) {
                if (this.payType == 'HK') {
                    this.HKshow = true
                    this.oid = res.data
                } else {
                    this.paymentClick(res.data)
                }
            }
        },
        // H5付款 购物车
        async paymentClickGWC(oId) {
            if (this.payType == 'WX') {
                var parme = {
                    type: 'mall',
                    payType: 'WX',
                    tradeType: 'NATIVE',
                    oId: JSON.stringify(oId),
                }
            } else {
                var parme = {
                    type: 'mall',
                    payType: 'AliPay',
                    oId: JSON.stringify(oId),
                    // returnUrl: `http://192.168.1.114:8081/myIndex/myOrder?actMyNum=0`
                    returnUrl: this.$store.state.returnUrl // 内网无法访问，这里是支付完成后，跳转测试
                }
            }
            const res = await postSourcingPayment(parme)
            if (res.statusCode == 8201) {
                let sourcingDataGWC = this.sourcingDataGWC
                let couponNum = ''
                if (this.payType == 'WX') {

                    // (sourcingDataGWC.reduce((total, obj) => total + obj.mallOrderItemVos.reduce((itemTotal, item) => itemTotal + (item.price * item.num), 0), 0) +
                    //     sourcingDataGWC.reduce((total, obj) => total + obj.freightPrice, 0) - sourcingDataGWC.reduce((total,
                    //         obj) => total + obj.yhqItemJE || 0, 0) || 0).toFixed(2) 

                    couponNum = (sourcingDataGWC.reduce((total, obj) => total + obj.mallOrderItemVos.reduce((itemTotal, item) => itemTotal + (item.price * item.num), 0), 0) +
                        sourcingDataGWC.reduce((total, obj) => total + obj.freightPrice, 0) - sourcingDataGWC.reduce((total,
                            obj) => total + obj.yhqItemJE || 0, 0) || 0).toFixed(2)
                    this.$router.push(`/payment/WeChatPayPage?codeUrl=${res.data.codeUrl}&outTradeNo=${res.data.outTradeNo}&couponNum=${couponNum}`)
                } else {
                    var tempwindow = window.open('_blank')
                    tempwindow.location = res.data
                }
            }
        },
        // 付款 H5
        async paymentClick(oId) {
            var oids = []
            oids.push(oId)

            if (this.payType == 'WX') {
                var parme = {
                    type: 'mall',
                    payType: 'WX',
                    tradeType: 'NATIVE',
                    oId: JSON.stringify(oids),
                }
            } else {
                var parme = {
                    type: 'mall',
                    payType: 'AliPay',
                    oId: JSON.stringify(oids),
                    // returnUrl: `http://192.168.1.114:8081/myIndex/myOrder?actMyNum=0`
                    returnUrl: this.$store.state.returnUrl // 内网无法访问，这里是支付完成后，跳转测试
                }
            }
            const res = await postSourcingPayment(parme)
            if (res.statusCode == 8201) {
                if (this.payType == 'WX') {
                    let couponNum = ''
                    if (this.paymentTypes == 'MS') {
                        couponNum = this.sourcingDataMS.seckillPrice + this.freightPrice
                    } else if (this.paymentTypes == 'PT') {
                        couponNum = this.sourcingDataPT.unitPriceXZ * this.sourcingDataPT.numXZ + this.freightPrice - this.yhqItemJE
                    } else {
                        couponNum = this.sourcingData.totalPrice + this.sourcingData.freightAmount
                    }
                    this.$router.push(`/payment/WeChatPayPage?codeUrl=${res.data.codeUrl}&outTradeNo=${res.data.outTradeNo}&couponNum=${couponNum}`)
                } else {
                    var tempwindow = window.open('_blank')
                    tempwindow.location = res.data
                }
            }
        },
        // 获取地址列表
        async getAddressList() {
            const res = await getAddress({
                start: 1,
                length: 100
            })
            if (res.statusCode == 8201) {
                this.addressList = res.data.data
                console.log(this.addressList, '地址')
                if (this.addressList.length == 0) {
                    this.$message.error('请先添加收货地址')
                }
                // 查运费
                if (this.paymentTypes == 'MS' || this.paymentTypes == 'PT') {
                    this.getFreightAllClick()
                }
                if (this.paymentTypes == 'GWC') {
                    this.getFreightAllClickGWC()
                }
            }
        },
        // 添加地址时选择是否默认
        isSelectedClickAdd() {
            this.isSelectedAdd = !this.isSelectedAdd
        },
        // 选择地址
        xuanAddreClick(index) {
            this.addressMore = index
        },
        paymentType(index) {
            this.paymentArr.map(t => t.act = false)
            this.paymentArr[index].act = true
            if (index == 1) {
                this.payType = 'ZFB'
                this.outInShow = false
            } else if (index == 0) {
                this.payType = 'WX'
                this.outInShow = false
            } else if (index == 2) {
                this.outInShow = true
                // 线下汇款
                this.payType = 'HK'
            }
            console.log(this.payType)
        },
        // 获取汇款账户信息
        async getStoreAccountById() {
            const res = await getStoreAccount()
            if (res.statusCode == 8201) {
                this.accountInfo = res.data
            }
        },
        // 取消，重置按钮
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.dialogVisible = false
            this.isSelectedAdd = false
            this.dialogVisibleEdit = false
        },
        // 确定添加地址按钮
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    this.loading = true
                    let data = {
                        pcc: this.ruleForm.receivingInformation.join(' '),
                        name: this.ruleForm.name,
                        province: this.ruleForm.receivingInformation[0],
                        city: this.ruleForm.receivingInformation[1],
                        county: this.ruleForm.receivingInformation[2],
                        address: this.ruleForm.detailedAddress,
                        telephone: this.ruleForm.phone,
                        defaults: this.isSelectedAdd ? 1 : 0,
                        channel: 'mall',
                        type: 'add'
                    }
                    console.log(data)
                    const res = await postAddress(data)
                    if (res.statusCode == 8201) {
                        this.$message.success(res.message)
                        this.dialogVisible = false
                        this.getAddressList()
                    } else {
                        this.$message.error(res.message)
                    }
                } else {
                    console.log('error submit!!', valid);
                    return false;
                }
            });
        },
        // 倒计时
        updateCountdown() {
            var now = new Date().getTime();
            var distance = this.countDownDate - now;
            if (distance <= 0) {
                clearInterval(this.timer); // 停止计时器
                return;
            }
            this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
            this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
        },
        formatNumber(num) {
            return num < 10 ? "0" + num : num;
        },
    }
}
</script>

<style lang="less" scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.element {
    background-color: lightblue;
    padding: 10px;
    margin-top: 10px;
}

.home {
    width: 100%;
    min-height: 100vh;
    background-color: #f5f5f5;

    >.payment_content {
        width: 1200px;
        height: auto;
        margin: 20px auto;
        padding: 20px;
        box-sizing: border-box;
        background-color: #fff;

        >.consigneeInfo {
            width: 100%;
            min-height: 100px;
            padding: 17px 28px;
            box-sizing: border-box;
            border: 1px solid #E0E0E0;

            >.tit {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                >.title {
                    font-size: 16px;
                    color: #333;
                    font-weight: bold;
                }

                >.add {
                    font-size: 12px;
                    color: #333;
                    cursor: pointer;
                    transition: all .3s;
                }

                >.add:hover {
                    color: #FF4242;
                    transition: all .3s;
                }
            }

            >.info {
                width: 100%;
                display: flex;
                align-items: center;
                font-size: 14px;
                color: #000;
                margin-top: 20px;

                >.img {
                    width: 16px;
                    height: 16px;
                    margin-right: 8px;

                    >img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                >.name {
                    margin-right: 31px;
                }

                >.address,
                .phone {
                    margin-right: 14px;
                }

                >.dz {
                    width: 60px;
                    height: 20px;
                    background: #D9D9D9;
                    border-radius: 10px;
                    font-size: 12px;
                    color: #666;
                    text-align: center;
                    line-height: 20px;
                }
            }
        }

        >.payment {
            width: 100%;
            height: 115px;
            padding: 14px 28px;
            box-sizing: border-box;
            border: 1px solid #E0E0E0;
            margin-top: 20px;

            >.title {
                width: 100%;
                font-size: 16px;
                color: #333;
                font-weight: bold;
            }

            .xxhk {
                width: 380px;
                height: 137px;
                padding: 12px 20px 12px 40px;
                box-sizing: border-box;
                background: url("../../assets/xxhkBg.png") no-repeat;
                background-size: 100% 100%;

                >.title {
                    font-size: 14px;
                    font-family: PingFang SC-Heavy, PingFang SC;
                    font-weight: 800;
                    color: #333333;
                    margin-bottom: 10px;
                }

                >.son {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    padding-right: 20px;
                    box-sizing: border-box;
                    margin-bottom: 7px;

                    >.l {
                        width: 63px;
                        font-size: 12px;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #666666;
                    }

                    >.z {
                        font-size: 12px;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #333333;
                        margin-right: 15px;
                    }

                    >.r {
                        font-size: 12px;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #666666;
                    }
                }
            }

            .zf {
                width: 120px;
                height: 40px;
                background: #FFFFFF;
                border-radius: 4px 4px 4px 4px;
                border: 1px solid #E0E0E0;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 20px;
                margin-top: 17px;
                cursor: pointer;
                color: #000;

                .img {
                    width: 20px;
                    height: 20px;
                    margin-right: 8px;

                    >img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                span {
                    font-size: 14px;
                }
            }
        }

        >.inDistribution {
            width: 100%;
            height: auto;
            margin-bottom: 20px;
            border: 1px solid #E0E0E0;
            margin-top: 20px;

            >.distributionInfo {
                width: 100%;
                height: 250px;
                display: flex;
                border-bottom: 1px solid #E0E0E0;

                >.l {
                    width: 250px;
                    height: 250px;
                    background-color: #F2F2F2;
                    border-right: 1px solid #E0E0E0;
                    padding: 18px 14px;
                    box-sizing: border-box;

                    >.titl {
                        font-size: 16px;
                        font-weight: bold;
                        color: #333;
                    }

                    >.bt {
                        width: 100px;
                        height: 30px;
                        background: #FFFFFF;
                        border-radius: 4px 4px 4px 4px;
                        border: 1px solid #FF4242;
                        font-size: 14px;
                        color: #FF4242;
                        text-align: center;
                        line-height: 30px;
                        margin-top: 15px;
                    }

                    >.by {
                        font-size: 14px;
                        color: #666;
                        margin-top: 25px;
                    }

                    >.yf {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 17px;

                        >.y {
                            font-size: 14px;
                            color: #666;
                        }

                        >.jg {
                            color: #FF4242;
                        }
                    }
                }

                >.r {
                    width: 910px;
                    height: 250px;
                    padding: 20px;
                    box-sizing: border-box;

                    >.top {
                        display: flex;

                        >.img {
                            width: 120px;
                            height: 120px;

                            >img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        >.parameter {
                            padding-top: 10px;
                            box-sizing: border-box;
                            margin-left: 35px;

                            >.merchantsName {
                                font-size: 14px;
                                color: #333;
                            }

                            >.cncInfo {
                                display: flex;
                                font-size: 14px;
                                color: #333;
                                margin-top: 20px;

                                >.cncName {
                                    width: 350px;
                                    font-size: 16px;
                                    margin-right: 50px;
                                }

                                >.num {
                                    margin-right: 35px;
                                }

                                >.dj {
                                    margin-right: 60px;

                                    >span {
                                        color: #FF4242;
                                    }
                                }
                            }

                            >.model {
                                margin-top: 15px;
                                font-size: 14px;
                                color: #999;
                            }
                        }

                    }

                    >.note {
                        margin-top: 20px;
                    }

                }
            }

            >.coupons {
                width: 100%;
                height: 208px;

                >.tit {
                    width: 100%;
                    padding: 15px;
                    box-sizing: border-box;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    >.doNotUse {
                        font-size: 14px;
                        color: #333;
                        display: flex;
                        align-items: center;

                        >.img {
                            width: 16px;
                            height: 16px;
                            margin-right: 8px;
                            cursor: pointer;

                            >img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        >.tet {
                            font-size: 14px;
                            color: #666;
                        }
                    }
                }

                >.swiper {
                    width: 100%;
                    // margin-top: 20px;

                    .yhq {
                        width: 100%;
                        padding: 0 40px;
                        box-sizing: border-box;
                        display: flex;
                        align-items: center;
                        // justify-content: space-between;
                        gap: 0px;

                        >.son {
                            width: 380px;
                            height: 120px;
                            margin-top: 20px;
                            background: url(../../assets/lqyhbg.png);
                            background-size: 100% 100%;
                            display: flex;
                            position: relative;

                            >.l {
                                width: 135px;
                                height: 120px;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;

                                >.jiag {
                                    color: #FF4242;
                                    font-size: 16px;

                                    >span {
                                        font-size: 30px;
                                        font-weight: bold;
                                    }
                                }

                                >.text {
                                    font-size: 12px;
                                    color: #666;
                                    margin-top: 5px;
                                }
                            }

                            >.r {
                                width: 210px;
                                height: 120px;
                                padding: 20px 0px;
                                box-sizing: border-box;

                                >.name {
                                    font-size: 14px;
                                    color: #333;
                                }

                                >.text {
                                    font-size: 12px;
                                    color: #999;
                                    margin-top: 2px;
                                }

                                >.time {
                                    width: 200px;
                                    margin-top: 20px;
                                    display: flex;
                                    justify-content: space-between;

                                    >.djs {
                                        font-size: 12px;
                                        color: #FF4242;
                                    }

                                    >.img {
                                        width: 16px;
                                        height: 16px;

                                        >img {
                                            width: 100%;
                                            height: 100%;
                                            object-fit: cover;
                                        }
                                    }
                                }


                                >.prompt {
                                    color: #999;
                                    font-size: 12px;
                                    margin-left: 120px;
                                    margin-top: 3px;
                                }
                            }

                            >img {
                                width: auto;
                                height: 18px;
                                position: absolute;
                                top: 4px;
                                left: 8px;
                            }
                        }
                    }
                }
            }
        }

        >.aTotalOf {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            margin-bottom: 10px;
            padding: 0 15px;
            box-sizing: border-box;

            >div {
                width: 100px;
                text-align: right;
                font-size: 16px;

            }

            >.payment {
                color: #666;
            }

            >.mount {
                color: #666;
            }
        }

        >.toCopeWith {
            width: 100%;
            // height: 80px;
            padding: 5px 15px;
            box-sizing: border-box;
            background-color: #EEEEEE;

            >.totalAmountOf {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                margin-bottom: 10px;
                align-items: center;

                >div {
                    min-width: 100px;
                    text-align: right;
                    font-size: 16px;
                }

                >.payment {
                    color: #666666;
                    margin-top: 4px;
                }

                >.mount {
                    font-size: 24px;
                    color: #FF4242;
                    font-weight: bold;
                    padding-top: 5px;
                }

                >span {
                    color: #666;
                    font-size: 14px;
                    margin-left: 20px;
                }
            }
        }

        >.btn {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            margin-top: 10px;

            >.tij {
                width: 140px;
                height: 48px;
                background: #FF4242;
                border-radius: 4px 4px 4px 4px;
                font-size: 20px;
                color: #fff;
                text-align: center;
                line-height: 48px;
                cursor: pointer;
            }
        }

    }
}

.mrdz {
    width: 100px;
    display: flex;
    font-size: 12px;
    color: #666;
    cursor: pointer;
    user-select: none;

    >.img {
        width: 16px;
        height: 16px;
        margin-right: 7px;

        >img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}


.pzDialo {
    // max-width: 800px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;

    >div {
        width: 400px;

        >.title {
            font-size: 16px;
            font-family: PingFang SC-Heavy, PingFang SC;
            font-weight: 800;
            color: #333333;
            margin-bottom: 15px;
            text-align: center;
        }

        >.con {
            width: 100%;
            height: 250px;
            padding: 15px 21px;
            box-sizing: border-box;
            background: #F3F6F8;
            border-radius: 8px 8px 8px 8px;

            >.ti {
                font-size: 14px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #666666;
                margin-bottom: 11px;
            }

            >.box {
                width: 100%;
                display: flex;
                align-items: center;
                margin-bottom: 20px;

                >.input {
                    width: 100%;
                    height: 36px;
                    border-radius: 4px;
                    border: 1px solid #B9B9B9;
                    padding: 0 12px;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #666666;
                }

                >input:focus {
                    border: 1px solid #B9B9B9;
                    outline: none;
                }

                >textarea {
                    width: 100%;
                    height: 52px;
                    background: #FFFFFF;
                    border-radius: 4px 4px 4px 4px;
                    border: 1px solid #B9B9B9;
                    padding: 7px 12px;
                    box-sizing: border-box;
                    resize: none
                }

                >textarea:focus {
                    border: 1px solid #B9B9B9;
                    outline: none;
                }

                >.text {
                    font-size: 14px;
                    font-family: PingFang SC-Heavy, PingFang SC;
                    font-weight: 800;
                    color: #333333;
                }

                >.fz {
                    font-size: 12px;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #666666;
                    margin-left: 10px;
                }
            }
        }
    }
}

/deep/ .el-upload--picture-card {
    width: 65px;
    height: 65px;
    line-height: 65px;

    >i {
        font-size: 16px;
    }
}

/deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 65px;
    height: 65px;
    object-fit: cover;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
    object-fit: cover;
}

/deep/ .el-dialog--center .el-dialog__body {
    padding: 25px 24px 10px;
}

// 右
.swiper-button-next {
    background-color: rgba(0, 0, 0, 0.3);
    margin-right: -10px;
}

.swiper-button-next:after,
.swiper-button-next:after {
    font-size: 22px;
    color: #fff;
}

// 左
.swiper-button-prev {
    background-color: rgba(0, 0, 0, 0.3);
    margin-left: -10px;
}

.swiper-button-prev:after,
.swiper-button-next:after {
    font-size: 22px;
    color: #fff;
}


.actShow {
    border: 1px solid #FF4242 !important;
    color: #FF4242 !important;
}

/deep/ .el-textarea__inner {
    background-color: #F7F7F7;
}

/deep/ .el-textarea .el-input__count {
    background-color: #F7F7F7;
}

/deep/ .el-textarea__inner:focus {
    border-color: #E0E0E0;
}
</style>